<template>
  <v-card class="elevation-1" :loading="formLoading" :disabled="formLoading">
    <v-card-text>
      <p class="mb-4 text-5">
        <v-icon>{{icon.info}}</v-icon> Select parameter then click generate.</p>
      <v-form>

        <!-- status -->
        <v-radio-group
          v-model="viewAs"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Table View"
            value="table-view"
          ></v-radio>
          <v-radio
            label="PDF"
            value="pdf"
          ></v-radio>
          <v-radio
            label="Excel"
            value="excel"
          ></v-radio>
        </v-radio-group>

        <!-- by -->
        <v-autocomplete
          v-model="groupBy"
          :items="['Employee', 'Department', 'Outlet']"
          outlined
          dense
          clearable
          label="Group By"
          :class="formErrorFields.groupByErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.groupByErrorMessage"
          @keydown="formError().remove('groupBy', formErrorFields)"
        ></v-autocomplete>

        <!-- range type -->
        <v-autocomplete
          v-model="rangeType"
          outlined
          dense
          clearable
          label="Range Type"
          item-value="id"
          item-text="name"
          :items="['Month', 'Cutoff', 'Custom']"
          :class="formErrorFields.rangeTypeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.rangeTypeErrorMessage"
          @change="formError().remove('rangeType', formErrorFields)"
        ></v-autocomplete>

        <!-- payroll period -->
        <template v-if="rangeType!=='Custom'">
          <v-dialog
            ref="dialog"
            v-model="modalPayrollPeriod"
            :return-value.sync="payrollPeriodValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :loading="payrollPeriodLoading"
                v-model="payrollPeriod"
                :label="rangeType == 'Cutoff' ? 'Payroll Period' : 'Date'"
                outlined
                dense
                :placeholder="rangeType == 'Cutoff' ? 'Payroll Period' : 'Date'"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                :append-icon="icon.calendar"
                :class="formErrorFields.payrollPeriodErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.payrollPeriodErrorMessage"

                @click="formError().remove('payrollPeriod', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="payrollPeriod"
              scrollable
              :type="rangeType==='Month' ? 'month' : 'date'">
              <v-btn text color="primary" @click="modalPayrollPeriod=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalPayrollPeriod=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>
        <template v-else>
          <!-- from date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                :append-icon="icon.calendar"
                :class="formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.fromDateErrorMessage"

                @click="formError().remove('fromDate', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" scrollable>
              <v-btn text color="primary" @click="modalFromDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalFromDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <!-- to date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                :append-icon="icon.calendar"
                :class="formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.toDateErrorMessage"

                @click="formError().remove('toDate', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" scrollable>
              <v-btn text color="primary" @click="modalToDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalToDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <v-btn
          color="primary"
          :loading="loading"
          @click="generate">
          Generate
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          @click="reset"
        >
          Reset
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendarOutline, mdiInformationOutline } from '@mdi/js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const icon = { calendar: mdiCalendarOutline, info: mdiInformationOutline }
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const payrollPeriodValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const activePayrollPeriod = ref('')
    const payrollPeriodLoading = ref(false)
    const payrollPeriod = ref('')
    const modalPayrollPeriod = ref(null)
    const fromDate = ref('')
    const modalFromDate = ref(null)
    const toDate = ref('')
    const modalToDate = ref(null)
    const viewAs = ref('table-view')
    const rangeType = ref('Cutoff')
    const groupBy = ref('Employee')
    const formLoading = ref(false)
    const formErrorFields = ref({
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
      payrollPeriodErrorMessage: '',
      groupByErrorMessage: '',
      rangeTypeErrorMessage: '',
    })
    const message = ref('')
    const messageStatus = ref('')
    const error = ref('')
    const reportData = ref(null)

    const generate = async () => {
      const formData = {
        groupBy: groupBy.value.toLowerCase(),
        rangeType: rangeType.value.toLowerCase(),
        payrollPeriod: payrollPeriod.value,
        fromDate: fromDate.value,
        toDate: toDate.value,
        viewAs: viewAs.value,
      }

      if (viewAs.value === 'table-view') {
        emit('sendFormData', formData)
      } else {
        let mimetype = ''
        if (viewAs.value === 'pdf') {
          mimetype = 'application/pdf'
        } else {
          mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }

        await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/wtax/${fnx.base64_encode(formData)}`, reportData, formLoading, error)
        if (error.value.data !== undefined) {
          message.value = error.value.data.message
          messageStatus.value = error.value.status
        } else {
          console.log(reportData.value)
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }
      }
    }

    const reset = () => {
      viewAs.value = 'table-view'
      groupBy.value = ''
      rangeType.value = ''
      payrollPeriod.value = ''
      fromDate.value = ''
      toDate.value = ''
    }

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayrollPeriod, payrollPeriodLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      } else {
        payrollPeriod.value = activePayrollPeriod.value.end
        fromDate.value = activePayrollPeriod.value.start
        toDate.value = activePayrollPeriod.value.end
      }
    }

    getPayPeriod()

    return {
      payrollPeriodLoading,
      payrollPeriod,
      modalPayrollPeriod,
      payrollPeriodValue,
      fromDate,
      modalFromDate,
      fromDateValue,
      toDate,
      modalToDate,
      toDateValue,
      viewAs,
      groupBy,
      rangeType,
      icon,
      formLoading,
      reset,
      generate,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,
    }
  },
}
</script>
